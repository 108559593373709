<template>
    <div class="homeOrder">
        <div class="title">我的寻源</div>

        <div class="detail">
            <div class="title">我的寻源详情</div>
            <div class="img_info">
                <div class="img">
                    <img :src="sourcingData.demandImgs?.split(`,`)[0]" alt="">
                </div>
                <div class="info">
                    <div class="son"><span>产品名称：</span> {{ sourcingData.productName }}</div>
                    <div class="son"><span>商品类目：</span> {{ sourcingData.categoryName }}</div>
                    <div class="son"><span>当前状态：</span> {{ type }}</div>
                    <div class="son"><span>需求数量：</span> {{ sourcingData.purchaseNum }}</div>
                    <div class="son"><span>单价上限：</span> {{ sourcingData.ceilingPrice }}</div>
                    <div class="son"><span>订单总额：</span> {{ sourcingData.orderPrice }}</div>
                    <div class="son"><span>发布时间：</span> {{ sourcingData.gmtCreate }}</div>
                    <div class="son"><span>截止时间：</span> {{ sourcingData.gmtDeadline }}</div>
                    <div class="son"><span>详情描述：</span>
                        <div>{{ sourcingData.description }}</div>
                    </div>
                </div>
            </div>

            <div class="tit_bj">当前报价({{ tableData.length }})</div>
            <br>
            <el-table :data="tableData" stripe style="width: 100%" border>
                <el-table-column prop="mallStore.storeName" label="供货商">
                </el-table-column>
                <el-table-column prop="mallSpu.title" label="产品名称">
                </el-table-column>
                <el-table-column prop="offerNumV" label="供求数量" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="unitPrice" label="单价" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="totalPrice" label="订单总额" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="freightAmount" label="运费金额" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="gmtDelivery" label="预计发货时间" header-align="center" align="center" width="180px">
                </el-table-column>
                <el-table-column prop="delivery" label="发货地" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="type" label="详情" header-align="center" align="center" width="120px">
                    <template slot-scope="scope">
                        <div style="width: 100%; display: flex;">
                            <div class="btn" @click="handleEdit(scope.$index, scope.row)">查看</div>
                            <div class="btn2" :class="[type == '已取消'? 'btn2QX' :'']" v-if="!scope.row.oid" @click="handleEdit2(scope.$index, scope.row)">下单</div>
                            <div class="btn2" style="width:50px"  v-else>已下单</div>
                        </div>

                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex;align-items: center;gap: 20px;">
                <div class="ann" @click="editClick">{{ type == '已取消' ? '已取消' : '取消寻源' }}</div>
                <!-- <div class="ann2">重新发布</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getSourcingInfo, getTypeIdInfo,postCancelSourcing } from "@/utils/api/myApi/index"
export default {
    name: 'mySourcingDetails',
    data() {
        return {
            // 列表数据
            tableData: [],
            // 商品id
            demandId: '',
            // 商品信息
            sourcingData: {},
            type: ''
        }
    },
    mounted() {
        this.demandId = this.$route.query.demandId
        this.getSourcingData()
        console.log(this.demandId)
    },
    methods: {
        // 查看详情
        handleEdit(index, row) {
            console.log(index, row);
            localStorage.setItem('sourcingData', JSON.stringify(this.sourcingData));
            window.open(`#/myIndex/mySourcingOfferDetails?index=${index}&actMyNum=3`, '_blank');
        },
        // 下单
        handleEdit2(index, row) {
            if(this.type == '已取消') return this.$message.warning('该寻源已取消')
            if(this.type == '已超时') return this.$message.warning('该寻源已超时')
            if(this.type == '已完成') return this.$message.warning('该寻源已完成')
            console.log(index, row);
            this.$set(this.sourcingData.mallPurchaseOfferList[index],'address',`${this.sourcingData.deliveryProvince} ${this.sourcingData.deliveryCity} ${this.sourcingData.deliveryCounty} ${this.sourcingData.deliveryAddress} `)
            localStorage.setItem('mallStoreObjXY', JSON.stringify(this.sourcingData.mallPurchaseOfferList[index]));
            this.$router.push('/payment')
        },
        // 查询寻源商品信息
        async getSourcingData() {
            const res = await getSourcingInfo(this.demandId)
            if (res.statusCode == 8201) {
                this.sourcingData = res.data
                this.tableData = res.data.mallPurchaseOfferList
                this.tableData.map(d => {
                    this.$set(d, 'offerNumV', `${d.offerNum}${d.mallSpu.unit || '个'}`)
                    this.$set(d, 'delivery', `${d.mallStore.province}${d.mallStore.city}${d.mallStore.county}${d.mallStore.address}`)
                })
                if (this.sourcingData.demandStatus == 'PROCESSING') this.type = '进行中'
                if (this.sourcingData.demandStatus == 'TIMEOUT') this.type = '已超时'
                if (this.sourcingData.demandStatus == 'CANCEL') this.type = '已取消'
                if (this.sourcingData.demandStatus == 'FINISHED') this.type = '已完成'
                const rej = await getTypeIdInfo(res.data.categroyId)
                console.log(rej, 123)
                if (rej.statusCode == 8201) {
                    this.$set(this.sourcingData, 'categoryName', rej.data[0].typeName)
                }
            }
        },
        // 取消寻源
        async editClick(){
            if(this.type == '已取消') return this.$message.warning('该寻源已取消')
            const res = await postCancelSourcing(this.demandId)
            if(res.data){
                this.$message.success('已取消寻源')
                this.type = '已取消'
            }else {
                this.$message.error(res.message)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.detail {
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;

        >.title {
            width: 100%;
            font-size: 14px;
            color: #000;
        }

        >.img_info {
            width: 100%;
            height: auto;
            display: flex;
            margin-top: 20px;

            >.img {
                width: 320px;
                height: 320px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.info {
                width: 620px;
                height: auto;
                margin-left: 20px;

                >.son {
                    width: 100%;
                    display: flex;
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 10px;

                    >span {
                        color: #666;
                        margin-right: 5px;
                    }

                    >div {
                        width: 540px;
                    }
                }
            }
        }

        >.tit_bj {
            color: #333;
            font-size: 14px;
            font-weight: bold;
            margin-top: 30px;
        }

    }
}

.ann {
    width: 120px;
    height: 40px;
    background: #F4F4F4;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #BFBFBF;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin-top: 20px;
}

.ann2 {
    width: 120px;
    height: 40px;
    background: #FF4242;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin-top: 20px;
}

.btn {
    width: 40px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FF4242;
    color: #FF4242;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    margin: 0 auto;
}

.btn2 {
    width: 37px;
    height: 22px;
    background: #FF4242;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FF4242;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    margin: 0 auto;
}
.btn2QX {
    border: 1px solid #BFBFBF;
    background: #f4f4f4;
    color: #666;
}
</style>